const Footer = () => {

    return (
        <footer>
            <p>Copyright &copy; 2021 Project Fuelture. All Rights Reserved.</p>
        </footer>
    )
}

export default Footer
